<script>
import baseComp from '#/v-shop-base-layout/vue-src/v-shop/appbar/comp_AppBar-Buttons.vue'

export default {
	extends: baseComp,
	computed: {
		bioData() {
			return this.user?.bioData
		},
		bioImage() {
			return this.user?.bioImage
		},
		nameInitials() {
			return this.bioData?.Alias.split(' ')
				.slice(0, 2)
				.map((n) => n[0])
				.join('')
				.toUpperCase()
		},
	},
}
</script>

<template>
	<div class="d-flex align-center justify-end">
		<hook zone="user">
			<v-btn
				v-if="user && $b.td"
				text
				:class="[btnClass, !noUserBtnText && 'mr-2']"
				:to="bioData ? { name: 'user.bio-profile' } : { name: 'user.account' }"
				:color="accountIconColor || $vars.basetext"
			>
				<v-icon :size="iconSize" class="btn" :class="!noUserBtnText && 'mr-1'" v-if="!bioData">
					mdi-account</v-icon
				>
				<v-img
					v-else-if="bioImage"
					width="48px"
					height="48px"
					class="rounded-circle"
					:class="{ 'mr-2': !noUserBtnText }"
					:src="`/bio-images/${bioImage}`"
					style="border: 1px solid rgba(17, 7, 148)"
					eager
				/>
				<v-avatar v-else size="48" color="rgba(17, 7, 148)" class="btn" :class="!noUserBtnText && 'mr-2'">
					<b class="white--text font-2">{{ nameInitials }}</b>
				</v-avatar>
				<span
					v-if="!noUserBtnText"
					class="font-1"
					:style="`color: ${accountIconColor || $vars.basetext}`"
					>{{ user.firstname }}</span
				>
			</v-btn>
			<v-btn
				v-if="!user && $b.td"
				text
				:class="[btnClass, !noUserBtnText && 'mr-2']"
				@click="showLoginDrawer"
				:color="accountIconColor || $vars.basetext"
				:loading="loginLoading"
			>
				<v-icon :size="iconSize" :class="!noUserBtnText && 'mr-1'">mdi-account</v-icon>
				<span v-if="!noUserBtnText" class="font-1">Ingresar</span>
			</v-btn>
		</hook>
		<hook zone="wishlist">
			<v-btn
				v-if="$b.td"
				text
				:class="[btnClass, !noWishlistBtnText && 'mr-2']"
				@click="goToFavorites"
				:color="favIconColor || $vars.basetext"
			>
				<v-icon :size="iconSize" :class="!noWishlistBtnText && 'mr-1'">mdi-heart</v-icon>
				<span v-if="!noWishlistBtnText" class="d-none d-lg-block font-1">Favoritos</span>
			</v-btn>
		</hook>
		<hook zone="cart">
			<v-btn
				v-if="purchaseMode != 'single_product'"
				text
				@click="showCartDrawer"
				class="cart-btn mr-md-0 px-0 px-md-2"
				:color="cartIconColor || $vars.basetext"
				id="show-cart-drawer-btn"
			>
				<v-badge :content="orderItemsQty" :value="!!orderItemsQty" overlap aria-label="carrito">
					<v-icon :size="iconSize" :class="!noCartBtnText && 'mr-1'">{{
						$g('cartIcon', 'mdi-cart')
					}}</v-icon>
				</v-badge>
				<span v-if="!noCartBtnText" class="d-none d-lg-block font-1">Carrito</span>
			</v-btn>
		</hook>
	</div>
</template>

<style lang="scss" scoped>
.text-transform-none {
	text-transform: none;
}
</style>

